import z from 'zod';
import { Big } from 'big.js';
import { coerceBoolean, zodNativeEnum, zodTDateISODate, zodTTime24 } from '~/lib/zod';
import { InvitedUserSchema } from '~/lib/schema/user';
import { AppointmentLock } from '~/lib/enum';

export const LatLngSchema = z.object({
  lat: z.coerce.number(),
  long: z.coerce.number(),
});

export type LatLng = z.infer<typeof LatLngSchema>;

const ADDRESS_ERROR = { required_error: 'Please lookup a valid address.' };

export const GeocodedAddressSchema = z.object(
  {
    line1: z.string(ADDRESS_ERROR),
    line2: z.coerce.string(ADDRESS_ERROR).optional().nullable(),
    city: z.string(ADDRESS_ERROR),
    state: z.string(ADDRESS_ERROR),
    zip: z.coerce.string(ADDRESS_ERROR),
    ...LatLngSchema.shape,
  },
  ADDRESS_ERROR,
);

export const DistanceAddressSchema = GeocodedAddressSchema.extend({
  distance: z.coerce.number().nullable(),
});

export type GeocodedAddress = z.infer<typeof GeocodedAddressSchema>;
export type DistanceAddress = z.infer<typeof DistanceAddressSchema>;

export const GeocodedAddressExample: DistanceAddress = {
  line1: '2 Bank Street',
  line2: null,
  city: 'New London',
  state: 'CT',
  zip: '06320',
  long: -72.09381669999999,
  lat: 41.3539585,
  distance: null,
};

export type Address = Omit<GeocodedAddress, 'latitude' | 'longitude'>;

export interface TenantOrderLine {
  id: string;
  amount: Big;
  description: string;
  discount?: boolean;
  phase?: 'submitted' | 'delivered' | 'completed';
  taxable?: boolean;
  estimate?: boolean;
  discountable?: boolean;
  job_id?: string;
  appointment_id?: string;
  fee?: 'holiday' | 'weekend' | 'travel' | 'rush' | 'tod';
}

export type TenantJobLine = Omit<TenantOrderLine, 'job_id'>;

export function addressToStreet(address: Address): string {
  return `${address.line1 || ''}${address.line2 ? ` ${address.line2.replace(/^##/, '#')}` : ''}`;
}

export function addressToArea(address: Address): string {
  return `${address.city || ''}, ${address.state || ''} ${address.zip || ''}`;
}

export function phoneFormat(phone: string): string {
  return phone.replace(/\+1(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export type DoJson<T> = {
  [K in keyof T]: Required<T>[K] extends Date ? string : T[K] extends object ? DoJson<T[K]> : T[K];
};

export const ScheduleUpdateSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('unassociate_job'),
    order_id: z.string(),
    appointment_id: z.coerce.string(),
    job_id: z.coerce.string(),
  }),
  z.object({
    type: z.literal('delete_draft'),
    order_id: z.string(),
  }),
  z.object({
    type: z.literal('unschedule_appointment'),
    order_id: z.string(),
    appointment_id: z.coerce.string(),
  }),
  z.object({
    type: z.literal('associate_job'),
    order_id: z.string(),
    appointment_id: z.coerce.string(),
    job_id: z.coerce.string(),
    minutes: z.coerce.number(),
  }),
  z.object({
    type: z.literal('create_appointment'),
    name: z.coerce.string().optional(),
    description: z.coerce.string().optional(),
    start: z.coerce.date(),
    order_id: z.string(),
    appointment_id: z.coerce.string(),
    provider_id: z.string(),
    override: z.coerce.number().optional(),
    locked: zodNativeEnum(AppointmentLock, true),
  }),
  z.object({
    type: z.literal('move_locked_appointment'),
    start: z.coerce.date(),
    order_id: z.string(),
    appointment_id: z.coerce.string(),
    provider_id: z.string(),
    override: z.coerce.number().optional(),
    locked: zodNativeEnum(AppointmentLock, true),
  }),
  z.object({
    type: z.literal('move_unlocked_appointment'),
    start: z.coerce.date(),
    order_id: z.string(),
    appointment_id: z.coerce.string(),
    provider_id: z.string(),
    override: z.coerce.number().optional(),
    locked: zodNativeEnum(AppointmentLock, true),
  }),
]);

export type ScheduleUpdate = z.infer<typeof ScheduleUpdateSchema>;

export const ScheduleUpdatesSchema = z.object({
  updates: ScheduleUpdateSchema.array(),
  notify_customer: coerceBoolean(),
  notify_provider: coerceBoolean(),
});

export type ScheduleUpdates = z.infer<typeof ScheduleUpdatesSchema>;

export class SessionState<D = Record<string, unknown>> {
  name!: string;
  data!: D;
}

export interface MapRoute {
  lat: number;
  lng: number;
  time: string;
}

export const UserTimeSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('day'),
    day: zodTDateISODate(),
  }),
  z.object({
    type: z.literal('custom'),
    day: zodTDateISODate(),
    notes: z.string(),
  }),
  z.object({
    type: z.literal('after'),
    day: zodTDateISODate(),
    time: zodTTime24('Please provide an after time.'),
  }),
  z.object({
    type: z.literal('before'),
    day: zodTDateISODate(),
    time: zodTTime24('Please provide a before time.'),
  }),
  z.object({
    type: z.literal('between'),
    day: zodTDateISODate(),
    start: zodTTime24('Please provide a start time.'),
    end: zodTTime24('Please provide an end time.'),
  }),
]);

export type UserTime = z.infer<typeof UserTimeSchema>;

export interface DisplayData {
  name: string;
  invoice?: boolean;
  provider?: boolean;
  customer?: boolean;
  schedule?: boolean;
  submit?: boolean;
  value: string | string[];
}

// based on luxon
export enum BusinessHoursDay {
  Sunday = 7,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const BusinessHoursSchema = z.object({
  start: zodTTime24('Please provide a start time.'),
  end: zodTTime24('Please provide an end time.'),
});

export type BusinessHours = z.infer<typeof BusinessHoursSchema>;

export interface OrderInvoiceLine {
  id: string;
  amount: TMoney;
  description: string;
  original: TMoney | null;
  hidden: boolean;
}

export interface ProviderStaticConfig {
  first: string;
  last: string;
  color?: string;
  hidden?: boolean;
  headshot: string;
  location: string;
  email: TEmail;
  phone: TPhone;
  start_address: GeocodedAddress;
  end_address?: GeocodedAddress;
  travel_start?: TTime24;
  travel_end?: TTime24;
  travel_end_ideal?: TTime24;
  schedule_start?: TTime24;
  schedule_end?: TTime24;
}

export const ProviderDynamicUserSchema = InvitedUserSchema.extend({});

export type ProviderDynamicUser = z.output<typeof ProviderDynamicUserSchema>;

export const ProviderDynamicConfigSchema = z.object({
  nylas_calendar_id: z.string().optional(),
  nylas_grant_id: z.string().optional(),
  nylas_access_token: z.string().optional(),
  nylas_refresh_token: z.string().optional(),
  nylas_expires_in: z.number().optional(),
  users: z.record(z.string(), ProviderDynamicUserSchema).default({}),
});

export type ProviderDynamicConfig = z.infer<typeof ProviderDynamicConfigSchema>;

export const PROVIDER_DATA_DEFAULT = (): ProviderDynamicConfig => ({ users: {} });
